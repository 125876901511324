 window.addEventListener("load", (event) => {
    const button = document.querySelectorAll(".list-button");
    const firstButton = document.querySelector(".list-button");
    const filesWrapper = document.querySelectorAll(".files-wrapper");
    const firstFilesWrapper = document.querySelector(".files-wrapper");



    if ( firstFilesWrapper){
    firstFilesWrapper.classList.add("show", "display");}

    if(firstButton){
    firstButton.classList.add("active");
}

    let id = "";
    let list = "";
    
    $(".list-button").click(function (e) {
      id = this.id;

      list = document.getElementsByClassName(id);
      list = list[0];



if (!this.classList.contains("active"))
{

      button.forEach((element) => {
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        }
      });

      e.currentTarget.classList.add("active");

      filesWrapper.forEach((element) => {
        if (element.classList.contains("show")) {
            element.classList.remove("display");
          setTimeout(function() {
            element.classList.remove("show");
          
        }, 300);
        }
        else{

      list.classList.add("display");
            setTimeout(function() {
                list.classList.add('show');
            }, 300);

        }
        
      
      });

    }
      
    });
  });

