const $news = $('.all-news .wrapper .news-wrapper article.tease');
const $moreButton = $('.all-news .wrapper').find('a.round-button.more-news');
let currentPage = 1;


if($news.length > 0) {
    let $totalPosts = $('#posts-total').data('total');
    let pageLang = $('section.all-news').data('lang');

    if ($('.wrapper .projects-wrapper .tease-project').length == $totalPosts) {
        $moreButton.addClass('hide');
    }

    $moreButton.click(function(e) {
        e.preventDefault();        
    
        currentPage++; // Do currentPage + 1, because we want to load the next page
        
        let data = new FormData();
        data.append('action', 'get_news');
        data.append('curr_lang', pageLang);
        data.append('paged', currentPage);
        $.ajax({
            type: 'POST',
            url: horisont_scripts.ajax_url,
            cache: false,
            processData: false,
            contentType: false,
            data: data,
            success: function (res) {
                $('.wrapper .news-wrapper').append(res);
              
                let $totalPosts = $('.wrapper #posts-total').data('total');
                if($('.wrapper .tease').length == 0) {
                    $('.wrapper .news-wrapper').append('<h3 class="no-articles-info">No results</h3>');
                }
                if ($('.wrapper .news-wrapper .tease').length < $totalPosts) {
                    $moreButton.removeClass('hide');
                }
                else {
                    $moreButton.addClass('hide');
                }
            }
        });

    });


    function getNews() {
        let data = new FormData();
        data.append('action', 'get_news');
        
        data.append('paged', currentPage);

        $.ajax({
            url: horisont_scripts.ajax_url,
            type: 'POST',
            data: data,
            cache: false,
            processData: false,
            contentType: false,
            error: function (data) {
                console.error(data);
            },
            complete: function (data) {
                document.querySelector('.news-wrapper').innerHTML = data.responseText;
               
                let $totalPosts = $('#posts-total').data('total');
                if($('.tease').length == 0) {
                    $('.news-wrapper').append('<h3 class="no-articles-info">No results</h3>');
                }
                if ($('.tease').length < $totalPosts) {
                    $moreButton.removeClass('hide');
                }
                else {
                    $moreButton.addClass('hide');
                }
            }
        });
    }

  
}