window.addEventListener("scroll", colorMenu);

let menu = $('.menu-transparent')
let body = $('body');
let heroImage = $('.hero');
let whiteText = false;
if(heroImage && heroImage.length > 0) {
    menu.addClass('menu-transparent');
    whiteText = true;
}

function colorMenu() {
    if (menu) {
        if(window.scrollY > 16 && window.innerWidth < 1440) {
            if (window.pageYOffset >= 40) {
                if(whiteText) {
                    menu.removeClass('menu-transparent');
                }
            } else if (window.pageYOffset < 40) {
                if(whiteText) {
                    menu.addClass('menu-transparent');
                }
            }
        }
        else {
            if (window.pageYOffset >= 120) {
                if(whiteText) {
                    menu.removeClass('menu-transparent');
                }
            } else if (window.pageYOffset < 120) {
                if(whiteText) {
                    menu.addClass('menu-transparent');
                }
            }
        }

    }
}