import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


let hero = document.querySelector('.hero');
let textHero = document.querySelector('.text-hero ');

let textHeroEl = document.querySelectorAll('.text-hero .wrapper * ');
let heroDel = document.querySelectorAll('.hero del');


gsap.registerPlugin(ScrollTrigger)



//add an intersection observer

//hero del animation
if (heroDel !== undefined && heroDel.length > 0) {
gsap.from(heroDel,{
    y: 50,
    stagger: 0.2,
    ease: "back.out",
    duration:0.8,
    opacity:0,
    scrollTrigger: {
        trigger : '.hero del',
        start: "top 80%",
    }
})
}

//project hero
if(textHero !== undefined && textHero !== null ) {
    gsap.from(textHeroEl,{
        y: 50,
        stagger: 0.2,
        ease: "back.out",
        duration:0.8,
        opacity:0,
        scrollTrigger: {
            trigger : '.text-hero .wrapper',
            start: "top 80%",
        }
    })
}

//stagger on news?