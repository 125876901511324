// hamburger nav

const breakpoint = 1440;

if(window.innerWidth < breakpoint) {
    window.addEventListener('load', (event) => {

        const button = document.querySelector(".menu-button")
        const header = document.querySelector("header")

        const buttonText = document.querySelector(".button-text")
  
        button.onclick = function toggleMenu() {
            if (header.matches('.show')) {
                
                setTimeout(function() {
                    
                    header.classList.remove('display');
                }, 300);
                   
                header.classList.remove('show');
                button.classList.remove("menu-close");
                
          
            }
            else {
                button.classList.add("menu-close");
                header.classList.add('display');

                setTimeout(function() {
                    header.classList.add('show');
                      
                }, 300);
               
            }

            

        };


    });


}

const languageSwitcher = document.querySelector('header .language-switcher');

if(languageSwitcher) {
    languageSwitcher.addEventListener('click', function() {
        if(languageSwitcher.classList.contains('open')) {
            languageSwitcher.classList.remove('open');
        }
        else {
            languageSwitcher.classList.add('open');
        }
    });
}