import Glide  from '@glidejs/glide'

let employeeBlock = document.querySelector('.employee-list');


if(employeeBlock) {
    var glide = new Glide('.glide', {
        type: 'carousel',
        startAt: 0,
        perView: 1.5,
        gap: 32,
        bound: 4,
        breakpoints: {
            600: {
                perView: 1,
                gap:16,
            },
            1024: {
                perView: 1.25,
                gap: 32,
            },
            1440: {
                perView: 1.5,
                gap: 32,
            }
        }
    });
    
    glide.on('build.after', function() {
        setTimeout(() => {
            var currentActive = document.querySelector("li.glide__slide.glide__slide--active");
            var heigtOfActive =  currentActive.children[2].scrollHeight;
            currentActive.children[2].style.maxHeight =  heigtOfActive+"px";
        }, "500");
    });
    
    glide.mount();
    
    const accordion = document.querySelectorAll(".accordion");
    
    glide.on('run', () => {
        accordion.forEach(function(accord) {
            accord.classList.remove("active");
            var panel = accord.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            }
        })
    })

    glide.on('run.after', () => {
        let checkMode = document.querySelectorAll(".glide-text");
        if(checkMode.length>0) {
            console.log("Mode 1");
            var allGlideElements = document.querySelectorAll("li.glide__slide");
            var currentActive = document.querySelector("li.glide__slide.glide__slide--active");
            var heigtOfActive =  currentActive.children[2].scrollHeight;
            for (let i=0; i < allGlideElements.length; i++) { 
                allGlideElements[i].children[2].style.maxHeight =  heigtOfActive+"px";
            }
        }
    })


    const acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    });
    }
}